<template>
  <div class="complete-registration">
    <v-row>
      <v-col>
        <h1 class="mb-4">Ut oh!</h1>
        <p>It looks like something went wrong trying to process your donation. No worries, click the button below to try again!</p>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-btn color="#84C9C8" :disabled="!checkout" :loading="!checkout" block dark x-large rounded @click="next">Complete Registration</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Cancel',

  data() {
    return {
      checkoutLink: null,
    }
  },

  computed: {
    ...mapGetters({
      participants: 'participant/list',
      checkouts: 'stripe-checkout/list'
    }),

    checkout() {
      return this.checkouts[this.checkouts.length - 1]
    },

    participant() {
      return this.participants[0]
    },

    charityId() {
      return this.$store.state.selectedCharityId
    },

    donationAmount() {
      return this.$store.state.donationAmount
    },
  },

  methods: {
    async next() {
      window.location.href = this.checkout.url
    }
  },

  beforeMount() {
    let charitySlug = null

    switch(this.charityId) {
      case 1:
        charitySlug = 'PP'
        break
      case 2:
        charitySlug = 'CASA'
        break
      case 3:
        charitySlug = 'RM'
        break
      case 4:
        charitySlug = 'NAMI'
        break
      case 5:
        charitySlug = 'LGBTQ'
        break
      case 6:
        charitySlug = 'FS'
        break
    }

    const priceId = this.$store.state.priceIds.find((o) => o.slug === `${charitySlug}${this.donationAmount}`)

    this.$store.dispatch('stripe-checkout/create', {
      priceId: priceId.id,
    })
  }


}
</script>

<style lang="scss">

</style>